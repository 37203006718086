<template>
  <div
    :class="[
      'custom-date-picker',
      { 'bg-white-red': redBackground && required && !selectedDate },
    ]"
    v-if="!squareLabeled || disabled"
    v-click-outside="closeDatePicker"
    @click="!disabled ? (onFocus = true) : ''"
  >
    <div v-if="isMobile">
      <input
        :type="type"
        :class="[
          { 'is-invalid': error !== '' },
          { 'no-placeholder': placeholder === '' },
          { disabled: disabled },
        ]"
        v-model="selectedDate"
        :disabled="disabled"
      />
    </div>

    <div class="input-picker" v-else>
      <datepicker
        :class="[
          'date-picker',
          { 'is-invalid': error !== '' },
          { 'no-placeholder': placeholder === '' },
          { disabled: disabled },
        ]"
        :style="styleDatePicker"
        :typeable="typeable"
        :disabled="disabled"
        :input-format="inputFormat"
        :minimum-view="minimumView"
        v-model="selectedDate"
        :locale="ja"
        @input="onChangeDatePicker"
        @blur="onAutoFormatDate"
      />
    </div>

    <label
      :class="[
        'floating-label',
        { 'on-focus': isMobile || selectedDate || onTyping },
      ]"
      label-date-picker="label-date-picker"
    >
      <span v-html="placeholder" />
      <span v-if="required && !disabled" class="required-label">必須</span>
    </label>

    <div class="error-input" v-if="error !== ''">
      <p v-html="error" />
    </div>
  </div>

  <div class="d-flex" v-else>
    <div class="square-label-block">
      <div
        :class="[
          'square-label',
          {
            'square-label-required': required,
            'square-label-optional': !required,
            'lang-en': $store.state.lang == 'en',
            'lang-ja': $store.state.lang == 'ja',
            'lang-kr': $store.state.lang == 'kr',
            'lang-my': $store.state.lang == 'my',
            'lang-np': $store.state.lang == 'np',
            'lang-pr': $store.state.lang == 'pr',
            'lang-sc': $store.state.lang == 'sc',
            'lang-tc': $store.state.lang == 'tc',
            'lang-tg': $store.state.lang == 'tg',
            'lang-vi': $store.state.lang == 'vi',
            'lang-ep': $store.state.lang == 'ep',
          },
        ]"
      >
        <p v-html="required ? $t('required') : $t('optional')" />
      </div>
    </div>

    <div
      class="custom-date-picker w-100"
      v-click-outside="closeDatePicker"
      @click="!disabled ? (onFocus = true) : ''"
    >
      <div v-if="isMobile">
        <input
          :type="type"
          :class="[
            { 'is-invalid': error !== '' },
            { 'no-placeholder': placeholder === '' },
            { disabled: disabled },
          ]"
          v-model="selectedDate"
          :disabled="disabled"
        />
      </div>

      <div class="input-picker" v-else>
        <datepicker
          :class="[
            'date-picker',
            { 'is-invalid': error !== '' },
            { 'no-placeholder': placeholder === '' },
            { disabled: disabled },
          ]"
          :style="styleDatePicker"
          :typeable="typeable"
          :disabled="disabled"
          :input-format="inputFormat"
          :minimum-view="minimumView"
          v-model="selectedDate"
          :locale="ja"
          @input="onChangeDatePicker"
          @blur="onAutoFormatDate"
        />
      </div>

      <label
        :class="[
          'floating-label',
          { 'on-focus': isMobile || selectedDate || onTyping },
        ]"
        label-date-picker="label-date-picker"
      >
        <span v-html="placeholder" />
      </label>

      <div class="error-input" v-if="error !== ''">
        <p v-html="error" />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vue3-datepicker";
import { STYLE_DATE_PICKER, MOBILE_LIST } from "@/common/constants.js";
import { convertDateNotTimeZone } from "@/common/helpers.js";
import constFunc from "@/common/constFunc.js";
import { ja } from "date-fns/locale";

export default {
  name: "DatePickerComponent",

  components: {
    Datepicker,
  },

  props: {
    type: {
      type: String,
      default: "date",
    },

    value: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    error: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    squareLabeled: {
      type: Boolean,
      default: false,
    },

    inputFormat: {
      type: String,
      default: "yyyy-MM-dd",
    },

    minimumView: {
      type: String,
      default: "day",
    },

    typeable: {
      type: Boolean,
      default: true,
    },

    redBackground: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      ja: ja,
      styleDatePicker: STYLE_DATE_PICKER,
      onFocus: false,
      onTyping: false,
      typedDate: this.value,
      selectedDate:
        this.$route.meta.layout == "mobile"
          ? this.value
          : !this.value
          ? this.value
          : new Date(this.value),
    };
  },

  created() {
    if (this.isMobile) {
      this.onFocus = true;
      return;
    }
  },

  computed: {
    isMobile() {
      return this.$route.meta.layout == "mobile";
    },

    isMobileDevice() {
      return MOBILE_LIST.test(navigator.userAgent);
    },
  },

  watch: {
    selectedDate: function (date) {
      if (date) {
        this.typedDate = convertDateNotTimeZone(date.toString());
      } else {
        this.typedDate = null;
      }
      if (date && convertDateNotTimeZone(date.toString()) != this.value) {
        this.$emit(
          "changeDate",
          date
            ? this.isMobile
              ? date
              : convertDateNotTimeZone(date.toString())
            : null
        );
      }
    },

    value: {
      handler: function () {
        if (!this.value) {
          this.selectedDate = null;
          } else {
            this.onFocus = true;
          if (this.selectedDate !== this.value) {
            this.selectedDate = new Date(this.value);
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    onChangeDatePicker(data) {
      const date = data.target.value;
      this.typedDate = date;
      if (date !== null && date !== "") {
        this.onTyping = true;
      } else {
        this.onTyping = false;
      }
    },

    closeDatePicker() {
      if (!this.isMobile && !this.disabled && !this.value) {
        this.onFocus = false;
      }
    },

    onAutoFormatDate() {
      if (
        this.typedDate &&
        !this.isMobile &&
        constFunc.validateDate(this.typedDate) &&
        (!this.selectedDate ||
          this.typedDate !=
            convertDateNotTimeZone(this.selectedDate.toString()))
      ) {
        const [year, month, day] = constFunc.validateDateNone(this.typedDate)
          ? [
              this.typedDate.slice(0, 4),
              this.typedDate.slice(4, 6),
              this.typedDate.slice(6, 8),
            ]
          : [
              this.typedDate.slice(0, 4),
              this.typedDate.slice(5, 7),
              this.typedDate.slice(8, 10),
            ];
        const formattedDate = `${year}-${month}-${day}`;
        this.selectedDate = new Date(formattedDate);
        this.$emit("changeDate", formattedDate);
      } else {
        // minimumView == "month"の場合、日付をクリックするとthis.valueが２回更新された後エラーになる
        // 例：雇用状況届出書出力ページなど
        // 詳しくはvue3-datepickerの動作に関して調査が必要
        // 暫定対応としてminimumView === "day"の条件を付ける
        if (this.minimumView === "day") {
          this.$emit("changeDate", this.typedDate);
        }
      }
    },
  },
};
</script>
