import axios from "axios";
import * as Sentry from "@sentry/browser";
import sessionStorage from "@/common/sessionStorage";
import { API_BASE_URL } from "@/common/constants";
import Store from "@/store";

const ApiService = () => {
  const defaultOptions = {
    baseURL: API_BASE_URL,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = sessionStorage.getToken();
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    // detect IE11 or IE10
    if (
      navigator.userAgent.match(/Trident.*rv:11\./) ||
      navigator.userAgent.match(/MSIE/i)
    )
      config.headers.common["Cache-Control"] = "no-cache";
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status !== 401) {
        Sentry.captureException(error); 
      }
      
      Store.state.errorStatus = error.response.status;
      Store.dispatch("tokenExpired", error.response.status);
    }
  );

  return instance;
};

export default ApiService();
