import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import Login from "../pages/login.vue";
import ResetPassword from "../pages/reset-password.vue";
import ResetPasswordMail from "../pages/reset-password-mail.vue";
import UploadImageSmartphone from "../pages/uploadImageSmartphone.vue";
import DashBoard from "../pages/dashboard.vue";
import UploadResidenceCard from "../pages/residence-card/index.vue";
import ResidenceCardUpdate from "../pages/residence-card-update/index.vue";
import RegisterCompany from "../pages/register-company/index.vue";
import PasswordNewCompany from "../pages/register-company/password.vue";
import Staff from "../pages/staff/staff";
import UploadDocument from "../pages/upload-document/index.vue";
import Store from "../store/index";
import { AUTHORITY } from "@/common/constants";

const checkLogined = (to, from, next) => {
  // 既にログインした場合
  if (Store.state.isLogin) {
    console.log("---Logined---");
    next("/");
  } else {
    next();
  }
};

const checkNotLogin = (to, from, next) => {
  Store.dispatch("connectedAdmin").then(() => {
    // 無限v3/adminリクエスト現象を防ぐため、isGoLogin変数を追加。もしまた無限v3/adminリクエスト現象が起きたら、他の対策を考える
    if (Store.state.isLogin === false && Store.state.isGoLogin === false) {
      console.log("---not Login---");
      Store.state.isGoLogin = true;
      next("login");
    } else {
      next();
    }
  });
};

const checkMasterRole = (to, from, next) => {
  if (Store.state.authority === AUTHORITY.MASTER) {
    next();
  } else {
    next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "dashboard",
    beforeEnter: checkNotLogin,
    component: DashBoard,
    meta: {
      title: "ダッシュボード",
      explain:
        "各アラートの対象人数や外国人雇用状況届出に係る書類の提出対象人数の確認ができます。",
    },
  },
  {
    path: "/login",
    name: "login",
    beforeEnter: checkLogined,
    component: Login,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/reset/password/mail",
    name: "resetPasswordMail",
    beforeEnter: checkLogined,
    component: ResetPasswordMail,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/reset/password",
    name: "resetPassword",
    beforeEnter: checkLogined,
    component: ResetPassword,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/staff",
    name: "staff",
    beforeEnter: checkNotLogin,
    component: Staff,
    meta: {
      title: "従業員管理",
      explain: "従業員の情報の観覧/編集を行うことができます。",
    },
    children: [
      {
        path: "list",
        name: "staffList",
        component: () =>
          import(
            /* webpackChunkName: "StaffList" */ "../pages/staff/staff-list.vue"
          ),
      },
      {
        path: "input/status",
        name: "inputStatus",
        component: () =>
          import(
            /* webpackChunkName: "StaffInputStatus" */ "../pages/staff/staff-input-status.vue"
          ),
      },
      {
        path: "update/target",
        name: "updateTarget",
        component: () =>
          import(
            /* webpackChunkName: "StaffUpdateTarget" */ "../pages/staff/staff-update-target.vue"
          ),
      },
      {
        path: "zaimane",
        name: "zaimane",
        component: () =>
          import(
            /* webpackChunkName: "Zaimane" */ "../pages/staff/zaimane.vue"
          ),
      },
      {
        path: "approval",
        name: "approvalList",
        component: () =>
          import(
            /* webpackChunkName: "Approval" */ "../pages/staff/approval-list.vue"
          ),
      },
    ],
  },

  {
    path: "/staff/zaimane/detail/app/:idCard",
    name: "StaffZaimaneDetailApp",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "StaffZaimaneDetailApp" */ "../pages/staff/zaimane-detail-app.vue"
      ),
    meta: {
      title: "アプリ読取内容",
      explain: "アプリで読み取った在留カード情報をご確認いただけます。",
      routerBack: {
        name: "zaimane",
      },
    },
  },

  {
    path: "/staff/detail",
    name: "staffDetail",
    beforeEnter: checkNotLogin,
    component: () => import("../pages/staff-detail/index.vue"),
    children: [
      {
        path: "basicInfo",
        name: "staffBasicInfo",
        component: () =>
          import(
            /* webpackChunkName: "StaffBasicInfo" */ "../pages/staff-detail/basic-info.vue"
          ),
        meta: {
          title: "従業員詳細",
          explain:
            "従業員の入社時に必要な情報の確認/編集を行うことができます。",
          routerBack: {
            name: "staffList",
          },
        },
      },
      {
        path: "residenceCard",
        name: "residenceCard",
        beforeEnter: checkNotLogin,
        component: () =>
          import(
            /* webpackChunkName: "ResidenceCard" */ "../pages/staff-detail/residence-card.vue"
          ),
        meta: {
          title: "従業員詳細",
          explain: "従業員の在留資格等の管理していただくことができます。",
          routerBack: {
            name: "staffList",
          },
        },
      },
      {
        path: "longVacation",
        name: "longVacation",
        beforeEnter: checkNotLogin,
        component: () =>
          import(
            /* webpackChunkName: "LongVacation" */ "../pages/staff-detail/long-vacation.vue"
          ),
        meta: {
          title: "従業員詳細",
          explain: "長期休暇の証明書の管理していただくことができます。",
          routerBack: {
            name: "staffList",
          },
        },
      },
      {
        path: "etc",
        name: "etc",
        beforeEnter: checkNotLogin,
        component: () =>
          import(/* webpackChunkName: "Etc" */ "../pages/staff-detail/etc.vue"),
        meta: {
          title: "従業員詳細",
          explain: "従業員から提出された資料の管理していただくことができます。",
          routerBack: {
            name: "staffList",
          },
        },
      },
      {
        path: "residenceCard/approval",
        name: "residenceCardApproval",
        beforeEnter: checkNotLogin,
        component: () =>
          import(
            /* webpackChunkName: "ResidenceCardApproval" */ "../pages/staff-detail/residence-card-approval.vue"
          ),
        meta: {
          title: "従業員詳細",
          explain:
            "アップロードされた新しい在留カードを確認して、申請処理を行ってください。",
          routerBack: {
            name: "staffList",
          },
        },
      },
      {
        path: "app",
        name: "staffApp",
        beforeEnter: checkNotLogin,
        component: () =>
          import(
            /* webpackChunkName: "StaffApp" */ "../pages/staff-detail/staff-app.vue"
          ),
        meta: {
          title: "従業員詳細",
          explain:
            "アップロードされた新しい在留カードを確認して、申請処理を行ってください。",
          routerBack: {
            name: "staffList",
          },
        },
      },
      {
        path: "informationApproval",
        name: "informationApproval",
        beforeEnter: checkNotLogin,
        component: () =>
          import(
            /* webpackChunkName: "InformationApproval" */ "../pages/staff-detail/information-approval.vue"
          ),
        meta: {
          title: "従業員詳細",
          explain:
            "従業員の入社時に必要な情報の確認/編集を行うことができます。",
          routerBack: {
            name: "staffList",
          },
        },
      },
    ],
  },

  {
    path: "/staff/workability/judgment",
    name: "staffWorkabilityJudgment",
    beforeEnter: [checkNotLogin],
    component: () =>
      import(
        /* webpackChunkName: "staffWorkabilityJudgment" */ "../pages/workability-judgment/index.vue"
      ),
    meta: {
      title: "就労可否判定",
      explain:
        "アプリで読み取った在留カード情報を元に就労が可能か判定ができます",
      layout: "no-side-menu",
    },
  },

  {
    path: "/staff/detail/old",
    name: "staffDetailOld",
    beforeEnter: checkNotLogin,
    component: () =>
      import(/* webpackChunkName: "Old" */ "../pages/staff-detail/old.vue"),
    meta: {
      title: "従業員過去データ確認",
      explain: "従業員一覧CSVフォーマットの編集をしていただけます。",
      routerBack: {
        name: "staffBasicInfo",
      },
    },
  },

  {
    path: "/staff/detail/basicInfo/old",
    name: "staffDetailBasicInfoOld",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "Old" */ "../pages/staff-detail/basic-info-old.vue"
      ),
    meta: {
      title: "従業員過去データ確認：基本情報",
      explain:
        "基本情報を変更した時点の過去のデータをご確認いただけます。（変更された項目を赤色で表示しております）",
      routerBack: {
        name: "staffBasicInfo",
      },
    },
  },
  {
    path: "/staff/detail/residenceCard/old",
    name: "staffDetailResidenceCardOld",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "Old" */ "../pages/staff-detail/residence-card-old.vue"
      ),
    meta: {
      title: "従業員過去データ確認：在留カード情報",
      explain:
        "在留カード情報を変更した時点の過去のデータをご確認いただけます。（変更された項目を赤色で表示しております）",
      routerBack: {
        name: "residenceCard",
      },
    },
  },
  {
    path: "/staff/detail/longVacation/old",
    name: "staffDetailLongVacationOld",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "Old" */ "../pages/staff-detail/long-vacation-old.vue"
      ),
    meta: {
      title: "従業員過去データ確認：長期休暇",
      explain:
        "長期休暇の証明書を変更した時点の過去のデータをご確認いただけます。",
      routerBack: {
        name: "longVacation",
      },
    },
  },
  {
    path: "/staff/detail/etc/old",
    name: "staffDetailEtcOld",
    beforeEnter: checkNotLogin,
    component: () =>
      import(/* webpackChunkName: "Old" */ "../pages/staff-detail/etc-old.vue"),
    meta: {
      title: "従業員過去データ確認：その他資料",
      explain: "その他書類を変更した時点の過去のデータをご確認いただけます。",
      routerBack: {
        name: "etc",
      },
    },
  },
  {
    path: "/staff/upload/residenceCard",
    name: "staffUploadResidenceCard",
    beforeEnter: checkLogined,
    component: UploadResidenceCard,
    meta: {
      layout: "mobile",
    },
  },

  {
    path: "/staff/upload/residenceCard/update",
    name: "residenceCardUpdate",
    beforeEnter: checkLogined,
    component: ResidenceCardUpdate,
    meta: {
      layout: "mobile",
    },
  },

  {
    path: "/staff/upload/document",
    name: "uploadDocument",
    beforeEnter: (to, from, next) => {
      //get and update query idS in url visamane2 to idStaff
      if (to.query.idS) {
        next(`/staff/upload/document?idStaff=${to.query.idS}`);
      } else if (to.query.idStaff) {
        next();
      } else {
        next("login");
      }
    },
    component: UploadDocument,
    meta: {
      layout: "mobile",
    },
  },

  {
    path: "/staff/csv/format",
    name: "CSVFormatList",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "CSVFormatList" */ "../pages/staff/csv-format-list.vue"
      ),
    meta: {
      title: "従業員CSVフォーマット一覧",
      explain: "従業員CSVフォーマットの編集をしていただけます。",
      routerBack: {
        name: "staffList",
      },
    },
  },

  {
    path: "/staff/csv/format/create",
    name: "CSVFormatCreate",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "CSVFormatCreate" */ "../pages/staff/csv-format-form.vue"
      ),
    meta: {
      title: "従業員CSVフォーマット作成",
      explain: "従業員一覧CSVフォーマットの新規作成ができます。",
      routerBack: {
        name: "CSVFormatList",
      },
    },
  },

  {
    path: "/csv/format/edit/:formatKind/:id",
    name: "CSVFormatEdit",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "CSVFormatEdit" */ "../pages/staff/csv-format-form.vue"
      ),
    meta: {
      title: "従業員CSVフォーマット編集",
      explain: "従業員一覧CSVフォーマットの編集ができます",
      routerBack: {
        name: "CSVFormatList",
      },
    },
  },

  {
    path: "/hellowork",
    name: "hellowork",
    beforeEnter: checkNotLogin,
    component: () => import("../pages/hellowork/index.vue"),
    meta: {
      title: "雇用状況届出書出力",
      explain: "ハローワークへ届け出る届出書の出力ができます。",
    },
    children: [
      {
        path: "electronic",
        name: "helloworkElectronic",
        component: () =>
          import(
            /* webpackChunkName: "HelloworkElectronic" */ "../pages/hellowork/hellowork-electronic.vue"
          ),
      },
      {
        path: "paper",
        name: "helloworkPaper",
        component: () =>
          import(
            /* webpackChunkName: "HelloworkPaper" */ "../pages/hellowork/hellowork-paper.vue"
          ),
      },
    ],
  },

  {
    path: "/hellowork/staff-list",
    name: "helloworkStaffList",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "HelloworkStaffList" */ "../pages/hellowork/staff-list.vue"
      ),
    meta: {
      title: "雇用状況届出書出力",
      explain: "ハローワークへ届け出る届出書の出力ができます。",
      routerBack: {
        name: "helloworkPaper",
        query: ["filter"],
      },
    },
  },

  {
    path: "/hellowork/working",
    name: "workingList",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "WorkingList" */ "../pages/hellowork/working-list.vue"
      ),
    meta: {
      title: "雇用状況届出書出力",
      explain: "ハローワークへ届け出る届出書の出力ができます。",
      routerBack: {
        name: "helloworkElectronic",
        query: ["filter"],
      },
    },
  },

  {
    path: "/hellowork/resigned",
    name: "resignedList",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "ResignedList" */ "../pages/hellowork/resigned-list.vue"
      ),
    meta: {
      title: "雇用状況届出書出力",
      explain: "ハローワークへ届け出る届出書の出力ができます。",
      routerBack: {
        name: "helloworkElectronic",
        query: ["filter"],
      },
    },
  },

  {
    path: "/company",
    name: "company",
    beforeEnter: checkNotLogin,
    component: () =>
      import(/* webpackChunkName: "Company" */ "../pages/company/company"),
    meta: {
      title: "企業管理",
      explain: "店舗/事業所の情報の観覧/編集を行うことができます。",
    },
    children: [
      {
        path: "shop/list",
        name: "shopList",
        component: () =>
          import(
            /* webpackChunkName: "ShopList" */ "../pages/company/shop-list"
          ),
      },
      {
        path: "office/list",
        name: "officeList",
        component: () =>
          import(
            /* webpackChunkName: "OfficeList" */ "../pages/company/office-list"
          ),
      },
    ],
  },

  {
    path: "/admin",
    name: "admin",
    beforeEnter: checkNotLogin,
    redirect: "/admin/list",
    children: [
      {
        path: "/admin/list",
        name: "adminList",
        beforeEnter: [checkNotLogin, checkMasterRole],
        component: () =>
          import(
            /* webpackChunkName: "AdminList" */ "../pages/admin/admin-list.vue"
          ),
        meta: {
          title: "ユーザー管理",
          explain: "ユーザーを追加・削除と権限を変更することができます。",
        },
      },
      {
        path: "/admin/mail/setting",
        name: "mailSetting",
        beforeEnter: [checkNotLogin, checkMasterRole],
        component: () =>
          import(
            /* webpackChunkName: "MailSetting" */ "../pages/admin/mail-setting.vue"
          ),
        meta: {
          title: "通知メール設定",
          explain: "通知メールに関する設定を変更できます",
        },
      },
      {
        path: "/admin/ip-address",
        name: "ipAddress",
        beforeEnter: [checkNotLogin, checkMasterRole],
        component: () =>
          import(
            /* webpackChunkName: "IpAddress" */ "../pages/ip-address/index.vue"
          ),
        meta: {
          title: "アクセス制限",
          explain:
            "IPアドレスやアプリ内の情報アクセスの制限を設定することができます。",
        },
      },
      {
        path: "/admin/feature/setting",
        name: "featureSetting",
        beforeEnter: [checkNotLogin, checkMasterRole],
        component: () =>
          import(
            /* webpackChunkName: "FeatureSetting" */ "../pages/admin/feature-setting.vue"
          ),
        meta: {
          title: "利用機能設定",
          explain: "情報承認等の特定機能の設定をすることができます。",
        },
      },
      {
        path: "/admin/customized",
        name: "customizedItem",
        beforeEnter: [checkNotLogin, checkMasterRole],
        component: () =>
          import(
            /* webpackChunkName: "CustomizedItem" */ "../pages/customized-item/index.vue"
          ),
        meta: {
          title: "回収必須項目設定",
          explain: "従業員の登録の際に回収したい項目を設定できます。",
        },
      },
      {
        path: "/admin/payment/list",
        name: "paymentList",
        beforeEnter: checkNotLogin,
        component: () => import("../pages/payment/payment-list.vue"),
        meta: {
          title: "請求詳細",
          explain: "ご利用のプランと請求履歴をご確認いただけます。",
        },
      },
    ],
  },

  {
    path: "/company/office/:idOffice",
    name: "officeDetail",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "OfficeDetail" */ "../pages/company/office-detail"
      ),
    meta: {
      title: "雇入れ事業所詳細",
      explain: "雇入れ事業所の情報の閲覧/編集を行うことができます。",
      routerBack: {
        name: "officeList",
      },
    },
  },

  {
    path: "/company/mainOffice/:idOffice",
    name: "mainOfficeDetail",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "MainOfficeDetail" */ "../pages/company/main-office-detail"
      ),
    meta: {
      title: "主たる事務所詳細",
      explain: "主たる事務所の情報の閲覧/編集を行うことができます。",
      routerBack: {
        name: "officeList",
      },
    },
  },

  {
    path: "/link/shop/office/:idOffice",
    name: "linkShopOffice",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "LinkShopOffice" */ "../pages/company/link-shop-office.vue"
      ),
    meta: {
      title: "店舗紐付け",
      explain: "関連付けする店舗を選択して紐付けることが出来ます",
      layout: "no-side-menu",
    },
  },

  {
    path: "/link/office/mainOffice/:idOffice",
    name: "linkOfficeMainOffice",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "LinkMainOffice" */ "../pages/company/link-office-mainOffice.vue"
      ),
    meta: {
      title: "雇入れ事業所紐付け",
      explain: "関連づけする雇入れ事業所を選択して追加することができます。",
      layout: "no-side-menu",
    },
  },

  {
    path: "/company/shop/:idShop",
    name: "shopDetail",
    beforeEnter: checkNotLogin,
    component: () =>
      import(
        /* webpackChunkName: "ShopDetail" */ "../pages/company/shop-detail"
      ),
    meta: {
      title: "店舗詳細",
      explain: "店舗の情報の観覧/編集を行うことができます。",
      routerBack: {
        name: "shopList",
      },
    },
  },

  {
    path: "/link/admin/shop/:idShop",
    name: "linkAdminShop",
    beforeEnter: [checkNotLogin],
    component: () =>
      import(
        /* webpackChunkName: "LinkAdminShop" */ "../pages/company/link-admin-shop.vue"
      ),
    meta: {
      title: "担当者紐付け",
      explain: "ユーザを店舗の担当者として設定していただけます。",
      layout: "no-side-menu",
    },
  },
  {
    path: "/admin/:idAdmin",
    name: "adminDetail",
    beforeEnter: [checkNotLogin, checkMasterRole],
    component: () =>
      import(
        /* webpackChunkName: "AdminDetail" */ "../pages/admin/admin-detail.vue"
      ),
    meta: {
      title: "ユーザー詳細",
      explain: "ユーザーの情報の閲覧/編集を行うことが出来ます",
      routerBack: {
        name: "adminList",
      },
    },
  },
  {
    path: "/shop-list/:idAdmin",
    name: "shopListAdmin",
    beforeEnter: [checkNotLogin, checkMasterRole],
    component: () =>
      import(
        /* webpackChunkName: "ShopListAdmin" */ "../pages/shop/shop-admin.vue"
      ),
    meta: {
      title: "店舗紐付け",
      explain: "関連付けする店舗を選択して紐付けることが出来ます",
      layout: "no-side-menu",
    },
  },

  {
    path: "/alert",
    name: "alert",
    beforeEnter: checkNotLogin,
    component: () =>
      import(/* webpackChunkName: "Alert" */ "../pages/admin/alert.vue"),
  },
  {
    path: "/admin/payment",
    name: "paymentDetail",
    beforeEnter: [checkNotLogin, checkMasterRole],
    component: () =>
      import(
        /* webpackChunkName: "PaymentDetail" */ "../pages/payment/payment-detail.vue"
      ),
    meta: {
      title: "請求詳細",
      explain: "ご利用のプランと請求履歴をご確認いただけます。",
      routerBack: {
        name: "paymentList",
      },
    },
  },
  {
    path: "/admin/payment-staff-list",
    name: "paymentStaffList",
    beforeEnter: checkNotLogin,
    component: () => import("../pages/payment/payment-staff-list.vue"),
    meta: {
      title: "{year}年{month}月請求対象者リスト",
      explain: "{year}年{month}月の請求対象の従業員をご確認いただけます。",
      routerBack: {
        name: "paymentList",
        query: ["year"],
      },
      customizeTitle: true,
      titleParams: ["year", "month"],
      customizeExplain: true,
      explainParams: ["year", "month"],
    },
  },
  {
    path: "/account",
    name: "account",
    beforeEnter: checkNotLogin,
    component: () =>
      import(/* webpackChunkName: "accountInfo" */ "../pages/account.vue"),
    meta: {
      title: "アカウント設定",
      explain: "アカウントに関する情報を変更することができます。",
    },
  },
  {
    path: "/companyInfoSetting",
    name: "companyInfoSetting",
    beforeEnter: [checkNotLogin, checkMasterRole],
    component: () =>
      import(
        /* webpackChunkName: "companyInfoSetting" */ "../pages/company-info-setting/company-info-setting.vue"
      ),
    meta: {
      title: "企業情報設定",
      explain: "企業情報を変更することができます。",
    },
  },
  {
    path: "/contact",
    name: "contact",
    beforeEnter: checkNotLogin,
    component: () =>
      import(/* webpackChunkName: "Contact" */ "../pages/contact.vue"),
    meta: {
      title: "お問い合わせ",
      explain: "不具合やプラン変更等のお問い合わせをしていただきます。",
    },
  },
  {
    path: "/notice",
    name: "notice",
    beforeEnter: checkNotLogin,
    component: () =>
      import(/* webpackChunkName: "Notice" */ "../pages/notice.vue"),
    meta: {
      title: "お知らせリスト",
      explain: "過去にお知らせした内容をご確認いただけます。",
    },
  },
  {
    path: "/register/company",
    name: "registerCompany",
    beforeEnter: checkLogined,
    component: RegisterCompany,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/uploadImageSmartphone",
    name: "uploadImageSmartphone",
    beforeEnter: checkLogined,
    component: UploadImageSmartphone,
    meta: {
      layout: "mobile",
    },
  },

  // set password for new company
  {
    path: "/company/email-verified",
    name: "passwordNewCompany",
    beforeEnter: checkLogined,
    component: PasswordNewCompany,
    meta: {
      layout: "auth",
    },
  },

  // ビザマネ２URL
  {
    path: "/admin/login",
    redirect: { name: "login" },
  },
  {
    path: "/update/verification",
    beforeEnter: (to, from, next) => {
      if (to.query.idSa) {
        next(`/staff/upload/residenceCard/update?idStaff=${to.query.idSa}`);
      } else if (to.query.hash) {
        next(`/staff/upload/residenceCard/update?idShop=${to.query.hash}`);
      } else {
        next("login");
      }
    },
  },
  {
    path: "/staff/register",
    beforeEnter: (to, from, next) => {
      if (to.query.hash) {
        next(`/staff/upload/residenceCard?idShop=${to.query.hash}`);
      } else {
        next("login");
      }
    },
  },

  {
    path: "/:catchAll(.*)",
    redirect: { name: "dashboard" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.FRONT_URL),
  routes,
});

trackRouter(router);

export default router;
